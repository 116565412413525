import { Suspense, lazy, useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import ReactGA from "react-ga";

import ThreeDLoader from "./components/threeDLoader/ThreeDLoader";

import Waitlist from "./pages/Waitlist";

const TRACKING_ID = process.env.REACT_APP_G_TAG_TRACKING_ID;
// Import your components using lazy loading
const ErrorPage = lazy(() => import("./pages/404"));

const AuthLayout = lazy(() => import("./layouts/AuthLayout"));

const SignUp = lazy(() => import("./pages/auth/SignUp"));

const Login = lazy(() => import("./pages/auth/SignIn"));

const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));

const UpdatePassword = lazy(() => import("./pages/auth/UpdatePassword"));

const AppLayout = lazy(() => import("./layouts/DashboardLayout"));

const DashboardPage = lazy(() => import("./pages/Dashboard"));

const Onboarding = lazy(() => import("./pages/onboarding/onboarding"));

const AvailableAssessment = lazy(() => import("./pages/assessment/AvailableAssessment"))

const ProfileSettings = lazy(

  () => import("./pages/usersettings/ProfileSettings")

);
const SocialIntegrations = lazy(() => import("./pages/usersettings/Socials"));


const SettingsLayout = lazy(() => import("./layouts/SettingsLayout"));

const PasswordSettings = lazy(

  () => import("./pages/usersettings/PasswordSettings")

);
const NotificationsSettings = lazy(
  () => import("./pages/usersettings/Notifications")
);
const PublicProfileSettings = lazy(
  () => import("./pages/usersettings/PublicProfile")
);
const AllChallenges = lazy(() => import("./pages/challenges/AllChallenges"));
const SingleChallenge = lazy(
  () => import("./pages/challenges/SingleChallenge")
);
const ChallengeTeams = lazy(() => import("./pages/challenges/ChallengeTeams"));

const Verification = lazy(() => import("./pages/auth/Verification"));

const PublicProfile = lazy(() => import("./pages/PublicProfile"));

const Teams = lazy(() => import("./pages/teams/Teams"));

const TeamOverview = lazy(() => import("./pages/teams/TeamOverview"));

const TeamLayout = lazy(() => import("./layouts/TeamLayout"));

const AssessmentLayout = lazy(() => import("./layouts/AssessmentLayout"));

const AssessmentResult = lazy(() => import("./pages/assessment/Result"));

const AsessmentIndex = lazy(() => import("./pages/assessment"));

const TeamsSettings = lazy(() => import("./pages/teams/TeamsSettings"));

const Testarea = lazy(() => import("./pages/assessment/Testarea"));

const Achievements = lazy(() => import("./pages/achievementTask/Achievements"));

const Messages = lazy(() => import("./pages/Messages"));

ReactGA.initialize(TRACKING_ID as string);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Suspense fallback={<ThreeDLoader active />}>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="teams/all" element={<Teams />} />
          <Route path="challenges" element={<AllChallenges />} />
          <Route path="messages" element={<Messages />} />
          <Route path="challenges/:challengeId" element={<SingleChallenge />} />
          <Route path="achievements" element={<Achievements />} />
          <Route
            path="challenges/:challengeId/teams"
            element={<ChallengeTeams />}
          />
          <Route path="teams/*" element={<TeamLayout />}>
            <Route path=":teamId/overview" element={<TeamOverview />} />
            <Route path=":teamId/settings" element={<TeamsSettings />} />
          </Route>
          <Route path="assessment/*" element={<AssessmentLayout />}>
            <Route path="" element={<AvailableAssessment />} />
            <Route path="single/:skillId" element={<AsessmentIndex />} />
            <Route path="result" element={<AssessmentResult />} />
            <Route path="test" element={<Testarea />} />
          </Route>
          <Route path="settings/*" element={<SettingsLayout />}>
            <Route
              index
              path="profile-settings"
              element={<ProfileSettings />}
            />
            <Route
              index
              path="password-settings"
              element={<PasswordSettings />}
            />
            <Route
              index
              path="public-profile"
              element={<PublicProfileSettings />}
            />
            <Route
              index
              path="notifications"
              element={<NotificationsSettings />}
            />
            <Route index path="socials" element={<SocialIntegrations />} />
          </Route>
        </Route>
        <Route path="auth/*" element={<AuthLayout />}>
          <Route path="signup" element={<SignUp />} />
          <Route path="verification" element={<Verification />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="update-password" element={<UpdatePassword />} />
        </Route>
        <Route path="onboarding/*" element={<AuthLayout />}>
          <Route index path="setup" element={<Onboarding />} />
        </Route>
        <Route path="beta" element={<Waitlist />} />
        <Route path="profile/:profileId" element={<PublicProfile />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
