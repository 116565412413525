import { Helmet } from 'react-helmet-async';
import AppLogo from "../resources/app-logo-text.png";
import mixin from '../utils/helperFunction';

interface SEOProps {
    title?: string;
    description?: string;
    type?: string;
    imageURL?: string;
    path?: string;
}
export default function SEOInject({ title, description, type, imageURL, path }:SEOProps) {
    const url = `${mixin.getHost()}${path}`;

return (
    <Helmet>
    { /* Standard metadata tags */ }
    <title>{title || 'Talenvo'}</title>
    <meta name='description' content={description || "Accelerate your career growth"} />
    { /* End standard metadata tags */ }
    { /* Facebook tags */ }
    <meta name="title" property="og:title" content={title || 'Talenvo'} />
      <meta property="og:type" content={type || "website"} />
      <meta name="image" property="og:image" content={imageURL || AppLogo} />
      <meta property="og:description" content={description || "Accelerate your career growth"} />
      <meta name='url' property="og:url" content={url} />
      <meta name='site_name' property="og:site_name" content="Talenvo" />
      { /* End Open Graph tags */ }

      { /* Twitter tags */ }
      {/* <meta  name="twitter:title" property="twitter:title" content={title  || 'Talenvo'} />
      <meta name="twitter:description" property="twitter:description" content={description || "Accelerate your career growth"} />
      <meta name="twitter:image" property="twitter:image" content={imageURL || AppLogo} />
      <meta name="twitter:card" property="twitter:card" content="summary"/> */}
      { /* End Twitter tags */ }

    </Helmet>
)
}