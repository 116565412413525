import { Link } from 'react-router-dom';
import AppLogo from "../resources/darkmode-logo.png";
import SEOInject from '../components/SEOInject';

export default function Waitlist() {

  // const inBeta = process.env.REACT_APP_PRODUCT_PHASE === 'beta';

  const computedStyles = () => {
    return {
      backgroundPosition: '90%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'black'
    }

  }
  return (
    <div className={` min-h-screen flex items-center justify-center waitlistPage px-4 lg:bg-[url('../resources/half-open-laptop.jpg')] `}  style={computedStyles()}>
      <SEOInject title='Talenvo | Waitlist'/>
       <div className="top-6 left-3 lg:left-6 w-full absolute  md:w-10/12">
            <div className="pl-8 pt- relative">
              <Link to={"/auth/login"}>
              <img src={AppLogo} alt="Talenvo App" />
              </Link>
              <span className="absolute text-red-500 border-0 -bottom-2 font-semibold rounded-md left-32 text-xs ">Beta</span>
            </div>
            </div>
      <div className="max-w-4xl mx-auto flex flex-col lg:flex-row items-center">
        <div className="text-center lg:text-left lg:w-1/2 lg:pr-10 mb-10 lg:mb-0 space-y-4">
          <h1 className="text-white text-3xl md:text-6xl font-bold mb-4">Thank You for Joining Talenvo!</h1>
          <p className="text-gray-300 pt-8 pb-2">
          Thank you for joining the waitlist. We are currently reviewing your application to confirm that your chosen job role and skill are a match for the program. 
We will notify you once you're in. 
          </p>
          <p className="text-gray-300 pt-2 pb-8">
          Stay tuned for value-packed, actionable resources designed to give tech professionals like you a head start and help you stand out in the competitive industry. 

          </p>
          <Link className="bg-white text-blue-900 py-3 px-8 rounded-lg shadow-lg hover:bg-gray-200" to={'https://blog.talenvo.co'}>
            You can begin here
          </Link>
        </div>
      </div>
    </div>
  );
}
