import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { dropDownOption } from '../../interfaces';

// THIS HOLDS ASSESSMENT INFO
export interface AssessStoreState {
    assessmentTitle: string;
    primarySkill: dropDownOption | null;
}

const initialState: AssessStoreState = {
    assessmentTitle: '',
    primarySkill: null,
};

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateAssessmentLabel: (state, action:PayloadAction<string>) => {
        state.assessmentTitle = action.payload;
    },
    updatePrimarySkill: (state, action:PayloadAction<dropDownOption>) => {
      state.primarySkill = action.payload
    }
  },
});

export const { updateAssessmentLabel, updatePrimarySkill } = assessmentSlice.actions;

export const label = (state: RootState) => (state.assessment as any).namesake;
export const assessmentTitle = (state: RootState) => (state.assessment as any).assessmentTitle as string;
export const selectedSkill = (state: RootState) => (state.assessment as any).primarySkill;

export default assessmentSlice.reducer;
