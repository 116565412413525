import axios from "axios";

let userToken = localStorage.getItem('talenvoJwToken');

const networkInstance = axios.create({
  baseURL: `${process.env.REACT_APP_ROOT_URL}`,
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
});

// Add a response interceptor
networkInstance.interceptors.response.use(
  response => {
    // If the response is successful, just return it
    return response;
  },
  error => {
    // If the response is unauthorized (401), redirect to the login page
    if (error.response && error.response.status === 401) {
      error.response.data.message = "Your session has expired, you will be redirected to the login page.";
      setTimeout(() => {
        window.location.href = '/auth/login';
      }, 4000)
    }
    // Return any other error to be handled
    return Promise.reject(error);
  }
);

export { networkInstance };
