import { createContext, useState, useEffect, FC, ReactNode } from 'react';
import io, { Socket } from 'socket.io-client';

const notificationsSocket = io(`${process.env.REACT_APP_ROOT_URL}`, {
  path: '/notifications-socket/'
});

const messagingSocket = io(`${process.env.REACT_APP_ROOT_URL}`, {
  path: '/messaging/'
});

interface SocketIOContextValue {
  messagingSocket: Socket;
  isMsgsConnected: boolean;
  notificationsSocket: Socket;
  isNotificationsConnected: boolean;
}
  
interface SocketIOProviderProps {
    children: ReactNode
}

const SocketIOContext = createContext<SocketIOContextValue>({
  messagingSocket: {} as Socket,
  isMsgsConnected: false,
  notificationsSocket: {} as Socket,
  isNotificationsConnected: false
});

const SocketIOProvider: FC<SocketIOProviderProps> = ({  children }) => {
    const [isMsgsConnected, setIsMsgsConnected] = useState(messagingSocket.connected);
    const [isNotificationsConnected, setIsNotificationsConnected] = useState(notificationsSocket.connected);



    useEffect(() => {
      const handleConnect = () => setIsMsgsConnected(true)
      const handleDisconnect = () => setIsMsgsConnected(false);

      const handleConnect1 = () => setIsNotificationsConnected(true)
      const handleDisconnect1 = () => setIsNotificationsConnected(false);

      messagingSocket.connect();
      notificationsSocket.connect()
  
      messagingSocket.on('connect', handleConnect);
      messagingSocket.on('disconnect', handleDisconnect);

      notificationsSocket.on('connect', handleConnect1);
      notificationsSocket.on('disconnect', handleDisconnect1);
  
      return () => {
        messagingSocket.off('connect', handleConnect);
        messagingSocket.off('disconnect', handleDisconnect);
        notificationsSocket.off('connect', handleConnect1);
        notificationsSocket.off('disconnect', handleDisconnect1);
        messagingSocket.disconnect();
        notificationsSocket.disconnect()
      };
    }, []);

  return (
    <SocketIOContext.Provider value={{ messagingSocket, isMsgsConnected, notificationsSocket, isNotificationsConnected }}>
      {children}
    </SocketIOContext.Provider>
  );
};

export { SocketIOContext, SocketIOProvider };
